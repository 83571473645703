import React, { FC } from 'react';
import { Button, ButtonPriority as PRIORITY, Text } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { DataHook } from '../../../../types';
import {
  useMember,
  useMemberHandlers,
  useCanEditMember,
} from '../../../../contexts/widgets';
import EditIcon from './EditIcon';
import { shouldRenderJoinDate, shouldRenderTitle } from './hooks';
import { classes, st } from './Header.st.css';

const formatDate = (dateString: string, language: string) => {
  return new Date(dateString).toLocaleDateString(language, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const Header: FC = () => {
  const settings = useSettings();
  const { t } = useTranslation();
  const { isMobile, language } = useEnvironment();
  const { createdDate } = useMember();
  const { canEditMember } = useCanEditMember();
  const { navigateToMyAccount } = useMemberHandlers();

  return (
    <>
      {shouldRenderTitle(isMobile) && (
        <div className={classes.titleWrapper}>
          <Text className={classes.title} tagName="h5">
            {t('about.widget.profile')}
          </Text>
          {canEditMember && (
            <Button
              type="button"
              data-hook={DataHook.EditProfileButton}
              className={classes.editProfileButton}
              priority={PRIORITY.secondary}
              prefixIcon={<EditIcon />}
              upgrade={true}
              onClick={() => navigateToMyAccount()}
            >
              {t('about.widget.edit-profile')}
            </Button>
          )}
        </div>
      )}
      {shouldRenderJoinDate(settings) && createdDate && (
        <Text
          tagName="div"
          className={st(classes.createdDate, { mobile: isMobile })}
        >
          {t('about-page.joined-date', {
            date: formatDate(createdDate, language),
          })}
        </Text>
      )}
    </>
  );
};
