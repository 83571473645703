import { FC } from 'react';
import Link from 'wix-ui-icons-common/on-stage/Link';
import DevianArt from 'wix-ui-icons-common/on-stage/DevianArt';
import Facebook from 'wix-ui-icons-common/on-stage/Facebook';
import Instagram from 'wix-ui-icons-common/on-stage/Instagram';
import LinkedIn from 'wix-ui-icons-common/on-stage/LinkedIn';
import Odnoklassniki from 'wix-ui-icons-common/on-stage/Odnoklassniki';
import Pinterest from 'wix-ui-icons-common/on-stage/Pinterest';
import SoundCloud from 'wix-ui-icons-common/on-stage/SoundCloud';
import TikTok from 'wix-ui-icons-common/on-stage/TikTok';
import Tumblr from 'wix-ui-icons-common/on-stage/Tumblr';
import Twitter from 'wix-ui-icons-common/on-stage/Twitter';
import Vimeo from 'wix-ui-icons-common/on-stage/Vimeo';
import VKontakte from 'wix-ui-icons-common/on-stage/VKontakte';
import Youtube from 'wix-ui-icons-common/on-stage/Youtube';

import { SocialType } from '../../../../../types';

export const iconMap: Record<SocialType, FC<{ className?: string }>> = {
  [SocialType.DEVIANTART]: DevianArt,
  [SocialType.FACEBOOK]: Facebook,
  [SocialType.INSTAGRAM]: Instagram,
  [SocialType.LINKEDIN]: LinkedIn,
  [SocialType.ODNOKLASSNIKI]: Odnoklassniki,
  [SocialType.OTHER]: Link,
  [SocialType.PINTEREST]: Pinterest,
  [SocialType.SOUNDCLOUD]: SoundCloud,
  [SocialType.TIKTOK]: TikTok,
  [SocialType.TUMBLR]: Tumblr,
  [SocialType.TWITTER]: Twitter,
  [SocialType.UNKNOWN]: Link,
  [SocialType.VIMEO]: Vimeo,
  [SocialType.VKONTAKTE]: VKontakte,
  [SocialType.YOUTUBE]: Youtube,
};
