import React, { FC } from 'react';
import { Tooltip } from 'wix-ui-tpa';
import InfoCircle from 'wix-ui-icons-common/InfoCircle';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { CustomProfile } from '../../../../../types';
import { useCanEditMember } from '../../../../../contexts/widgets';
import settingsParams from '../../../settingsParams';
import SectionTitle from '../../common/SectionTitle';
import { classes, st } from './Title.st.css';

interface TitleProps {
  customProfile: CustomProfile;
}

interface ShouldShowHelpTooltipOptions {
  customProfile: CustomProfile;
  canEditMember: boolean;
  isMobile: boolean;
}

const shouldShowHelpTooltip = ({
  customProfile: { fields, socialLinks },
  canEditMember,
  isMobile,
}: ShouldShowHelpTooltipOptions) => {
  return (
    !isMobile && canEditMember && (fields.length > 0 || socialLinks.length > 0)
  );
};

export const Title: FC<TitleProps> = ({ customProfile }) => {
  const { canEditMember } = useCanEditMember();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const settings = useSettings();

  return (
    <div className={st(classes.root)}>
      <SectionTitle>
        {settings.get(settingsParams.overviewSectionTitle)}
      </SectionTitle>
      {shouldShowHelpTooltip({
        customProfile,
        canEditMember,
        isMobile,
      }) && (
        <Tooltip
          className={classes.tooltip}
          skin={'wired' as any}
          content={t('about.widget.overview-tooltip')}
          placement="top-end"
          minWidth={200}
        >
          <InfoCircle className={classes.icon} />
        </Tooltip>
      )}
    </div>
  );
};
