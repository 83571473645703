import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import settingsParams from '../../settingsParams';

export const shouldRenderJoinDate = (
  settings: ReturnType<typeof useSettings>,
) => {
  return (
    settings.get(settingsParams.showAboutSection) &&
    settings.get(settingsParams.showJoinDate)
  );
};

export const shouldRenderTitle = (isMobile: boolean) => !isMobile;

export const useHeaderRenderer = () => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  const isTitleRendered = shouldRenderTitle(isMobile);
  const isJoinDateRendered = shouldRenderJoinDate(settings);

  return {
    shouldRenderHeader: isTitleRendered || isJoinDateRendered,
  };
};
