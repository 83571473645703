import React, { FC } from 'react';
import { Text, TextButton } from 'wix-ui-tpa';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { DataHook } from '../../../../../types';
import { useBlogPosts } from '../../../../../contexts/widgets';
import settingsParams from '../../../settingsParams';
import { useCreatePostClickHandler } from '../hooks';
import { classes, st } from './EmptyState.st.css';

export const EmptyState: FC = () => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { createBlogPostUrl } = useBlogPosts();
  const { handleCreatePostLinkClick } = useCreatePostClickHandler();

  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <div className={classes.content}>
        <Text tagName="div" className={classes.title}>
          {settings.get(settingsParams.blogPostsEmptyStateTitle)}
        </Text>
        <div className={classes.createPostLinkContainer}>
          <TextButton
            data-hook={DataHook.WriteYourFirstPostLink}
            className={classes.createPostLink}
            as="a"
            href={createBlogPostUrl.url}
            onClick={handleCreatePostLinkClick}
          >
            {settings.get(settingsParams.blogPostsEmptyStateDescription)}
          </TextButton>
        </div>
      </div>
    </div>
  );
};
