import { useContext } from 'react';

import { WidgetContext } from './Provider';

export const useInstance = () => {
  const { instance } = useContext(WidgetContext);
  return { instance };
};

export const useDefaultBIParams = () => {
  const { defaultBIParams } = useContext(WidgetContext);
  return { defaultBIParams };
};

export const useMember = () => useContext(WidgetContext).member;

export const useBlogPosts = () => {
  const { member, blogPosts } = useContext(WidgetContext);
  return { ...blogPosts, posts: member.blogPosts };
};

export const useSite = () => useContext(WidgetContext).site;

export const useAppSettings = () => useContext(WidgetContext).appSettings;

export const useUI = () => useContext(WidgetContext).ui;

export const useMemberHandlers = () => useContext(WidgetContext).memberHandlers;

export const useBlogPostsHandlers = () => {
  return useContext(WidgetContext).blogPostsHandlers;
};

export const useSiteHandlers = () => useContext(WidgetContext).siteHandlers;

export const useUIHandlers = () => useContext(WidgetContext).uiHandlers;

export const useCanEditMember = () => {
  const { sameSessionMember } = useSite();
  const { isPublicProfilePreview } = useUI();

  return { canEditMember: sameSessionMember && !isPublicProfilePreview };
};
