import React, { FC, MouseEventHandler } from 'react';
import { Divider, Text } from 'wix-ui-tpa';
import Comment from 'wix-ui-icons-common/on-stage/Comment';
import Visible from 'wix-ui-icons-common/on-stage/Visible';
import LikeHeart from 'wix-ui-icons-common/on-stage/LikeHeart';
import LikeHeartFill from 'wix-ui-icons-common/on-stage/LikeHeartFill';
import {
  useEnvironment,
  useBi,
  useTranslation,
  VisitorLogger,
} from '@wix/yoshi-flow-editor';
import { maMiscClickUou } from '@wix/bi-logger-members-app-uou/v2';

import {
  BlogNavigationReference,
  BlogPost,
  DataHook,
} from '../../../../../../types';
import { useSiteHandlers } from '../../../../../../contexts/widgets';
import Cover, { useCoverRenderer } from './Cover';
import { usePostRenderer } from './hooks';
import { classes, st } from './Post.st.css';

const formatDate = (dateString: string, language: string) => {
  return new Date(dateString).toLocaleDateString(language, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

const shouldShowPostCounters = (
  showPostViewsCounter: boolean,
  showPostCommentsCounter: boolean,
  showPostLikesCounter: boolean,
) => showPostViewsCounter || showPostCommentsCounter || showPostLikesCounter;

const reportPostClick = (bi: VisitorLogger) => {
  bi.report(maMiscClickUou({ referralInfo: BlogNavigationReference.ViewPost }));
};

export const Post: FC<BlogPost> = ({
  coverMedia,
  firstPublishedDate,
  minutesToRead,
  url,
  title,
  excerpt,
  postCountInfo,
}) => {
  const bi = useBi();
  const { t } = useTranslation();
  const { language, isPreview, isMobile } = useEnvironment();
  const { shouldRenderCover } = useCoverRenderer(coverMedia);
  const {
    showPostPublishDate,
    showPostReadingTime,
    showPostExcerpt,
    showPostViewsCounter,
    showPostCommentsCounter,
    showPostLikesCounter,
  } = usePostRenderer();
  const { navigateToPath } = useSiteHandlers();
  const shouldShowCounters = shouldShowPostCounters(
    showPostViewsCounter,
    showPostCommentsCounter,
    showPostLikesCounter,
  );
  const postUrl = `${url.base}${url.path}`;
  const handlePostLinkClick: MouseEventHandler = (event) => {
    reportPostClick(bi);

    if (isPreview) {
      event.preventDefault();
      event.stopPropagation();
      navigateToPath(url.path!);
    }
  };

  return (
    <div
      data-hook={DataHook.Post}
      className={st(classes.root, { mobile: isMobile })}
    >
      {shouldRenderCover && <Cover coverMedia={coverMedia} />}
      <div className={classes.content}>
        {(showPostPublishDate || showPostReadingTime) && (
          <Text tagName="div" className={classes.dateBar}>
            {showPostPublishDate && (
              <span className={classes.text}>
                {formatDate(firstPublishedDate, language)}
              </span>
            )}
            {showPostPublishDate && showPostReadingTime && (
              <span className={classes.text}>{' ∙ '}</span>
            )}
            {showPostReadingTime && (
              <span className={classes.text}>
                {minutesToRead} {t('recent-posts.post-card.minutes')}
              </span>
            )}
          </Text>
        )}
        <a
          className={classes.postLink}
          href={postUrl}
          onClick={handlePostLinkClick}
        >
          <Text tagName="div" className={classes.title}>
            {title}
          </Text>
          {showPostExcerpt && (
            <Text tagName="div" className={classes.excerpt}>
              {excerpt}
            </Text>
          )}
        </a>
      </div>
      {shouldShowCounters && (
        <div className={classes.footer}>
          <Divider className={classes.divider} />
          <Text tagName="div" className={classes.statsBar}>
            <div className={classes.viewsAndCommentsCounter}>
              {showPostViewsCounter && (
                <div className={classes.counter}>
                  <Visible />
                  <span>{postCountInfo.views ?? 0}</span>
                </div>
              )}
              {showPostCommentsCounter && (
                <div className={classes.counter}>
                  <Comment />
                  <span>{postCountInfo.comments ?? 0}</span>
                </div>
              )}
            </div>
            {showPostLikesCounter && (
              <a
                className={classes.likesCounter}
                href={postUrl}
                onClick={handlePostLinkClick}
              >
                {postCountInfo.likes! > 0 && <span>{postCountInfo.likes}</span>}
                {postCountInfo.likes ? <LikeHeartFill /> : <LikeHeart />}
              </a>
            )}
          </Text>
        </div>
      )}
    </div>
  );
};
