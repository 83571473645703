import { ToastSkin as TOAST_SKIN } from 'wix-ui-tpa';
import { TFunction } from '@wix/yoshi-flow-editor';

import { Toast } from '../../../../../types';

export const getToastContent = (toast: Toast, t: TFunction) => {
  let toastSkin;
  let toastContent: string | null;

  switch (toast) {
    case Toast.AboutPublish:
      toastContent = t('about-page.success-message');
      toastSkin = TOAST_SKIN.success;
      break;
    default:
      toastContent = null;
      toastSkin = TOAST_SKIN.success;
  }

  return {
    toastContent,
    toastSkin,
  };
};
